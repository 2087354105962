import deepcopy from 'clone';
import window from 'window-shim';
import TransactionConstants from 'spa/constants/TransactionConstants';

const {
  TRANSACTION_TYPES_CATEGORY,
  CATEGORY_OTHER_MERCHANDISE,
  TRANSACTION_ROLES,
  TRANSACTION_TYPES,
} = TransactionConstants;

const dollarsToCents = (amount) => Math.round(amount * 100);

const centsToDollars = (amount) => (amount / 100).toFixed(2);

export const getTransactionTypeCategoryAndLabel = (categoryValue, type) => {
  let categoryTypeAndLabel = {};

  if (!categoryValue) return categoryTypeAndLabel;

  categoryTypeAndLabel = { category: type, label: `Other: ${categoryValue}` };
  if (type) {
    for (const { label, value } of TRANSACTION_TYPES_CATEGORY[type]) {
      if (value === categoryValue) {
        categoryTypeAndLabel = { category: type, label };
      }
    }
  } else {
    for (const [key, categoryGroup] of Object.entries(TRANSACTION_TYPES_CATEGORY)) {
      for (const { label, value } of categoryGroup) {
        if (value === categoryValue) {
          categoryTypeAndLabel = { category: key, label };
        }
      }
    }
  }
  return categoryTypeAndLabel;
};

export const getCategoryValueFromOption = (categoryOption) => {
  for (const categoryGroup of Object.entries(TRANSACTION_TYPES_CATEGORY)) {
    for (const { label, value } of categoryGroup[1]) {
      if (label === categoryOption) {
        return value;
      }
    }
  }

  return categoryOption.replace('Other: ', '');
};

export const getCategoryOptionFromValue = (categoryValue) => {
  for (const categoryGroup of Object.entries(TRANSACTION_TYPES_CATEGORY)) {
    for (const { label, value } of categoryGroup[1]) {
      if (value === categoryValue) {
        return { label, value };
      }
    }
  }
  return { label: `Other: ${categoryValue}`, value: categoryValue };
};

export const formatCurrency = (value, currency = 'USD') => {
  // TODO: make a robust implementation.
  try {
    if (typeof value === 'string') {
      const num = parseFloat(value);
      return new Intl.NumberFormat('ja-US', { style: 'currency', currency }).format(num);
    }
    if (typeof value === 'number') {
      if (isNaN(value)) {
        return '---';
      }
      return new Intl.NumberFormat('ja-US', { style: 'currency', currency }).format(value);
    }
  } catch (e) {
    return 'Invalid Amount';
  }
};

function getDomainNames(item) {
  if (item.domainNames) {
    const domainNames = item.domainNames.filter((s) => s && s.length > 0);
    if (domainNames) {
      return domainNames;
    }
  }
  return null;
}

const getExtraAttributes = (payload, item, transactionType) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.MOTOR_VEHICLE:
      return {
        vin: item.vin,
        odometer: item.odometer,
        year: item.year,
        make: item.make,
        model: item.model,
        title_collection: Boolean(item.titleCollection),
        lien_holder_payoff: Boolean(item.lienHolder),
      };
    case TRANSACTION_TYPES.DOMAIN_NAME: {
      const domains = getDomainNames(item);
      if (domains) {
        return {
          domain_names: domains,
        }
      }
      return {};
    }
    case TRANSACTION_TYPES.DOMAIN_NAME_HOLDING: {
      const years = payload.years || 0;
      const months = payload.months || 0;
      const ret = {
        concierge: Boolean(item.concierge),
        with_content: item.category === 'website',
        term_period: years * 12 + months,
        dns_manager: item.dnsManagedBy,
      };
      const domains = getDomainNames(item);
      if (domains) {
        ret.domain_names = domains;
      }
      return ret;
    }
    default:
      return {};
  }
};

const getGlobalExtraAttributes = (firstItem, transactionType) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.DOMAIN_NAME:
      return {
        concierge: firstItem.category !== 'website' && Boolean(firstItem.concierge),
        with_content: firstItem.category === 'website',
      };
    default:
      return {};
  }
};

export const isEscrowPay = (payload) =>
  !payload.buyerEmail && payload.role !== TRANSACTION_ROLES.BUYER;

export function getBrokerFeeSchedule(
  totalBrokerFee,
  emails,
  role,
  hasScheduledBrokerComm,
  paymentSchedule,
  items
) {
  if (hasScheduledBrokerComm && role !== TRANSACTION_ROLES.SELLER) {
    let paymentScheduleIndex = 0;
    const brokerCommissionSchedule = [];
    const schedule = deepcopy(paymentSchedule);
    let parentReference = 0;
    for (const item of items) {
      let runningItemTotal = 0;
      const itemPrice = dollarsToCents(item.price);
      while (runningItemTotal < itemPrice) {
        const commission = dollarsToCents(parseFloat(schedule[paymentScheduleIndex].commission));
        const amount = dollarsToCents(parseFloat(schedule[paymentScheduleIndex].amount));
        if (runningItemTotal + amount > itemPrice) {
          const difference = itemPrice - runningItemTotal;
          runningItemTotal += difference;
          schedule[paymentScheduleIndex].commission -= centsToDollars(commission);
          schedule[paymentScheduleIndex].amount -= centsToDollars(difference);
        } else {
          runningItemTotal += amount;
          paymentScheduleIndex++;
        }
        brokerCommissionSchedule.push({
          amount: centsToDollars(commission),
          payer_customer: emails[role],
          beneficiary_customer: emails[TRANSACTION_ROLES.BROKER],
          parent_reference: `schedule_${parentReference}_reference`,
        });
        parentReference++;
      }
    }
    return brokerCommissionSchedule;
  }
  return [
    {
      amount: totalBrokerFee,
      payer_customer: emails[role],
      beneficiary_customer: emails[TRANSACTION_ROLES.BROKER],
    },
  ];
}

function getVisibility(disclosure, buyerEmail, sellerEmail) {
  if (disclosure === 'confidential') {
    return { visibility: { hidden_from: [buyerEmail, sellerEmail] } };
  }
  if (disclosure === 'transparentToBuyerOnly') {
    return { visibility: { hidden_from: [sellerEmail] } };
  }
  if (disclosure === 'transparentToSellerOnly') {
    return { visibility: { hidden_from: [buyerEmail] } };
  }
}

export const formatFormToV4 = (payload, feesSummary = false) => {
  try {
    const partyObject = [];

    const emails = {
      [TRANSACTION_ROLES.BUYER]: payload.buyerEmail || 'checkout_user',
      [TRANSACTION_ROLES.SELLER]: payload.sellerEmail || (feesSummary ? 'seller_user' : null),
      [TRANSACTION_ROLES.BROKER]: payload.brokerEmail,
    };
    emails[payload.role] = window.escrowUser.email;

    /* Transaction Parties */
    const disclosure =
      payload.role === TRANSACTION_ROLES.BROKER &&
      payload.transactionDisclosure !== 'transparentToBuyerAndSeller' &&
      payload.transactionDisclosure;
    const agreed = {
      [TRANSACTION_ROLES.BUYER]: false,
      [TRANSACTION_ROLES.SELLER]: false,
    };
    agreed[payload.role] = true;
    if (emails[TRANSACTION_ROLES.BUYER] === 'checkout_user') {
      agreed[TRANSACTION_ROLES.BUYER] = true;
      agreed[TRANSACTION_ROLES.SELLER] = true;
      agreed[TRANSACTION_ROLES.BROKER] = true;
    }

    partyObject.push({
      role: TRANSACTION_ROLES.BUYER,
      customer: emails[TRANSACTION_ROLES.BUYER],
      agreed: agreed[TRANSACTION_ROLES.BUYER],
      ...(payload.buyerPhone && { phone_number: payload.buyerPhone }),
      ...(['confidential', 'transparentToBuyerOnly'].includes(disclosure) && {
        visibility: {
          hidden_from: [emails[TRANSACTION_ROLES.SELLER]],
        },
      }),
    });
    partyObject.push({
      role: TRANSACTION_ROLES.SELLER,
      customer: emails[TRANSACTION_ROLES.SELLER],
      agreed: agreed[TRANSACTION_ROLES.SELLER],
      ...(payload.sellerPhone && { phone_number: payload.sellerPhone }),
      ...(['confidential', 'transparentToSellerOnly'].includes(disclosure) && {
        visibility: {
          hidden_from: [emails[TRANSACTION_ROLES.BUYER]],
        },
      }),
    });
    if (emails[TRANSACTION_ROLES.BROKER]) {
      partyObject.push({
        role: TRANSACTION_ROLES.BROKER,
        customer: emails[TRANSACTION_ROLES.BROKER],
        agreed: agreed[TRANSACTION_ROLES.BROKER],
      });
    }

    /* Transaction Type/Category */
    let transactionType = payload.items[0].itemType;
    if (transactionType === TRANSACTION_TYPES.GENERAL_MERCHANDISE) {
      if (payload.items[0].isMilestone) {
        transactionType = TRANSACTION_TYPES.MILESTONE;
      }
    } else if (transactionType === TRANSACTION_TYPES.DOMAIN_NAME) {
      if (payload.items[0].isDNH) {
        transactionType = TRANSACTION_TYPES.DOMAIN_NAME_HOLDING;
      }
    }

    /* FEES */
    const escrowFee = (payload.escrowFeePayer || TRANSACTION_ROLES.BUYER)
      .split(',')
      .map((role, _, array) => ({
        payer_customer: emails[role],
        type: 'escrow',
        split: array.length === 1 ? 1 : 0.5,
      }));
    let conciergeFee = [];
    if (payload.items[0].concierge) {
      conciergeFee = payload.escrowFeePayer.split(',').map((role, _, array) => ({
        payer_customer: emails[role],
        type: 'concierge',
        split: array.length === 1 ? 1 : 0.5,
      }));
    }

    const brokerCommissionPayer = payload.brokerCommissionPayer || TRANSACTION_ROLES.SELLER;
    const isBrokerCommPayerSplit = brokerCommissionPayer.split(',').length > 1;
    const hasScheduledBrokerComm =
      !feesSummary &&
      transactionType === TRANSACTION_TYPES.DOMAIN_NAME_HOLDING &&
      emails[TRANSACTION_ROLES.BROKER] &&
      brokerCommissionPayer !== TRANSACTION_ROLES.SELLER;
    let brokerFeeItems = [];
    if (emails[TRANSACTION_ROLES.BROKER]) {
      if (transactionType === TRANSACTION_TYPES.MILESTONE) {
        brokerFeeItems = payload.items.reduce((result, item, index) => {
          const brokerFeeItem = [];
          for (const payer of brokerCommissionPayer.split(',')) {
            brokerFeeItem.push({
              type: 'broker_fee',
              parent_reference: `item_${index}_reference`,
              schedule: [
                {
                  amount: isBrokerCommPayerSplit
                    ? item.brokerCommission / 2
                    : item.brokerCommission,
                  payer_customer: emails[payer],
                  beneficiary_customer: emails[TRANSACTION_ROLES.BROKER],
                },
              ],
              ...(disclosure && getVisibility(disclosure, emails.buyer, emails.seller)),
            });
          }
          return [...result, ...brokerFeeItem];
        }, []);
      } else {
        const totalBrokerFee = payload.items.reduce((a, c) => a + Number(c.brokerCommission), 0);

        brokerFeeItems = brokerCommissionPayer.split(',').map((role, _, array) => ({
          type: 'broker_fee',
          schedule: [
            ...getBrokerFeeSchedule(
              totalBrokerFee / array.length,
              emails,
              role,
              hasScheduledBrokerComm,
              payload.paymentSchedule,
              payload.items
            ),
          ],
          ...(disclosure && getVisibility(disclosure, emails.buyer, emails.seller)),
        }));
      }
    }

    let shippingFeeItems = [];
    let shippingFeePayer;
    payload.items.forEach((item) => {
      if (item.shippingMethod !== 'no_shipping') {
        shippingFeePayer = item.shippingFeePayer;
      }
    });

    if (transactionType === TRANSACTION_TYPES.MILESTONE) {
      shippingFeeItems = payload.items.reduce((result, item, index) => {
        if (item.shippingFeePayer === TRANSACTION_ROLES.BUYER) {
          result.push({
            type: 'shipping_fee',
            parent_reference: `item_${index}_reference`,
            schedule: [
              {
                amount: item.shippingFee,
                payer_customer: emails[TRANSACTION_ROLES.BUYER],
                beneficiary_customer: emails[TRANSACTION_ROLES.SELLER],
              },
            ],
          });
        }
        return result;
      }, []);
    } else {
      const totalShippingFee = payload.items.reduce((a, c) => a + Number(c.shippingFee), 0);

      if (totalShippingFee > 0 && shippingFeePayer === TRANSACTION_ROLES.BUYER) {
        shippingFeeItems = [
          {
            type: 'shipping_fee',
            schedule: [
              {
                amount: totalShippingFee,
                payer_customer: emails[TRANSACTION_ROLES.BUYER],
                beneficiary_customer: emails[TRANSACTION_ROLES.SELLER],
              },
            ],
          },
        ];
      }
    }

    const globalExtraAttributes = getGlobalExtraAttributes(payload.items[0], transactionType);
    let paymentScheduleIndex = 0;
    let scheduleReference = 0;
    const payloadPaymentSchedule = deepcopy(payload.paymentSchedule);
    const items = payload.items.map((item, index) => {
      let itemCategory;
      let itemCustomCategory;
      if (transactionType === TRANSACTION_TYPES.DOMAIN_NAME) {
        if (item.category === 'domain') {
          itemCategory = TRANSACTION_TYPES.DOMAIN_NAME;
        }
      } else if (transactionType !== TRANSACTION_TYPES.DOMAIN_NAME_HOLDING) {
        itemCategory = item.category;
        if (
          transactionType === TRANSACTION_TYPES.GENERAL_MERCHANDISE ||
          transactionType === TRANSACTION_TYPES.MILESTONE
        ) {
          const isCustomCateg = !TRANSACTION_TYPES_CATEGORY[
            TRANSACTION_TYPES.GENERAL_MERCHANDISE
          ].some((option) => option.value === item.category);
          if (isCustomCateg) {
            itemCategory = CATEGORY_OTHER_MERCHANDISE;
            itemCustomCategory = item.category;
          }
        }
      }

      const ret = {
        title: item.name,
        description: item.description,
        category: itemCategory,
        ...(itemCustomCategory && { custom_category: itemCustomCategory }),
        type: transactionType,
        inspection_period: (item.itemInspectionPeriod || payload.inspectionPeriod) * 86400, // api is in seconds,
        fees: [...escrowFee, ...conciergeFee],
      };
      if (item.shippingMethod) {
        ret.shipping_type = item.shippingMethod;
      }
      if (transactionType === TRANSACTION_TYPES.MILESTONE) {
        ret.reference = `item_${index}_reference`;
      }
      if (transactionType === TRANSACTION_TYPES.DOMAIN_NAME_HOLDING) {
        let runningItemTotal = 0;
        const itemSchedule = [];
        const itemPrice = dollarsToCents(item.price);
        while (runningItemTotal < itemPrice) {
          const schedule = payloadPaymentSchedule[paymentScheduleIndex];
          let amount = 0;
          const scheduleAmount = dollarsToCents(parseFloat(schedule.amount));
          if (runningItemTotal + scheduleAmount > itemPrice) {
            const scheduleDifference = itemPrice - runningItemTotal;
            runningItemTotal += scheduleDifference;
            payloadPaymentSchedule[paymentScheduleIndex].amount -= parseFloat(
              centsToDollars(scheduleDifference)
            );
            amount = scheduleDifference;
          } else {
            runningItemTotal += scheduleAmount;
            amount = scheduleAmount;
            paymentScheduleIndex++;
          }
          itemSchedule.push({
            amount: centsToDollars(amount),
            payer_customer: emails[TRANSACTION_ROLES.BUYER],
            beneficiary_customer: emails[TRANSACTION_ROLES.SELLER],
            due_date: schedule.date.toISOString(),
            ...(hasScheduledBrokerComm && { reference: `schedule_${scheduleReference}_reference` }),
          });
          scheduleReference++;
        }
        ret.schedule = itemSchedule;
      } else {
        ret.schedule = [
          {
            amount: item.price,
            payer_customer: emails[TRANSACTION_ROLES.BUYER],
            beneficiary_customer: emails[TRANSACTION_ROLES.SELLER],
          },
        ];
      }
      ret.extra_attributes = getExtraAttributes(payload, item, transactionType);
      ret.extra_attributes = { ...ret.extra_attributes, ...globalExtraAttributes };
      return ret;
    });

    return {
      parties: partyObject,
      currency: payload.currency,
      description: payload.title,
      items: [...items, ...brokerFeeItems, ...shippingFeeItems],
    };
  } catch (error) {
    console.error(error); //eslint-disable-line
  }
};

/**
 * This function is only used when react-phone-input-2 is used in the dom.
 * The two-character country code is set as the class name of drop down country selector.
 * @returns two digit country code or null
 */
export function getPhoneCountry(index = 0) {
  const allSelectors = document.querySelectorAll('.selected-flag > .flag');
  if (allSelectors && allSelectors.length > 0) {
    const selector = allSelectors[index];
    const countryCode = selector && selector.classList[1];
    return countryCode || null;
  }
  return null;
}

export function getPrefillFields(query) {
  const { amount, ttype, description } = query;
  const itemUpdateObject = {};
  if (amount) {
    itemUpdateObject.price = amount;
  }
  switch (ttype) {
    case 'DNQP':
      itemUpdateObject.itemType = TRANSACTION_TYPES.DOMAIN_NAME;
      break;
    case 'MVQP':
      itemUpdateObject.itemType = TRANSACTION_TYPES.MOTOR_VEHICLE;
      break;
    case 'GMQP':
    case 'MSQP':
      itemUpdateObject.itemType = TRANSACTION_TYPES.GENERAL_MERCHANDISE;
      break;
    default:
      break;
  }
  switch (description) {
    case 'A Domain Name':
    case 'Domains':
      itemUpdateObject.category = 'domain';
      break;
    case 'a Motor Vehicle':
    case 'Cars, Trucks, etc.':
      itemUpdateObject.category = 'automobiles_cars_and_trucks';
      break;
    case 'Art':
    case ' An art':
      itemUpdateObject.category = 'arts_and_crafts';
      break;
    case 'An Antique':
    case 'Antiques':
      itemUpdateObject.category = 'antiques_and_collectibles';
      break;
    case 'Electronics':
      itemUpdateObject.category = 'electronics';
      break;
    case 'Jewelry':
      itemUpdateObject.category = 'jewellery_and_watches';
      break;
    case 'Contracted Services':
    case 'Milestone Transactions':
      itemUpdateObject.category = 'services';
      itemUpdateObject.isMilestone = true;
      break;
    case 'Software':
      itemUpdateObject.category = 'computer_hardware_and_software';
      break;
    case 'Tickets':
      itemUpdateObject.category = 'tickets_and_events';
      break;
    default:
      break;
  }
  return itemUpdateObject;
}

export const updateFormValues = (form, name, newValues) => {
  Object.entries(newValues).forEach(([key, value]) => {
    form.change(`${name}.${key}`, value);
  });
};
