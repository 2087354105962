import React, { useState } from 'react';
import { Field, useForm } from 'react-final-form';
import {
  Autocomplete,
  FormControlLabel,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Switch,
  TextField,
  Paper,
  Dialog,
  Box,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Input } from 'spa/components/MaterialUI';
import { SubmitButton } from 'spa/components/StartTransaction';
import Spinner from 'spa/components/Indicators/Spinner';
import PhoneNumberInput from 'react-phone-input-2';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { useWindowDimensions } from 'spa/hooks';
import OlarkApi from '../../../utils/OlarkApi';
import { phoneValidate } from '../form/validate';
import { getCategoryValueFromOption } from './util';

const currencySymbols = {
  usd: '$',
  aud: 'A$',
  gbp: '£',
  eur: '€',
  cad: 'CA$',
};

const InputField = ({
  name,
  validate,
  label,
  defaultHelperText,
  children,
  shrinkLabel,
  isNumeric,
  disabled,
  select,
  initialValue,
  onChange,
  placeholder,
  multiline,
  validateFields,
  touchBeforeError = true,
  currency,
  autoFocus,
  format,
  parse,
  formatOnBlur,
  highlightOnFocus,
  onBlur,
  onFocus,
  refCallback,
}) => {
  const { change } = useForm();

  return (<Field
    name={name}
    validate={validate}
    initialValue={initialValue}
    disabled={disabled}
    validateFields={validateFields}
    format={format}
    parse={parse}
    formatOnBlur={formatOnBlur}
  >
    {({ input, meta }) => {
      /*
      Final-form doesn't revalidate fields when they're changed by formatters.
      So, when we have such a field, let's make blurring it go through a 2-step dance:
      First, change the field's value from the formatter.
      Then, refocus and reblur the field to make final-form validate it post-format.
      This is all only necessary because final-form doesn't provide an API for validate().
       */
      const originalOnBlur = input.onBlur;
      if (onBlur) {
        input.onBlur = (event) => {
          onBlur(event);
          if (originalOnBlur) {
            originalOnBlur(event);
          }
        };
      }

      const originalOnFocus = input.onFocus;
      input.onFocus = (event) => {
        originalOnFocus(event);
        if (onFocus) {
          onFocus(event);
        }
      };

      if (formatOnBlur && format && validate) {
        input.onBlur = () => {
          const formattedValue = format(input.value)
          if (formattedValue !== input.value) {
            change(name, formattedValue)
            const validationResult = validate(formattedValue)
            if (validationResult) {
              input.onFocus();
              if (originalOnBlur) {
                originalOnBlur();
              }
            }
          }
        }
      }
      return (<Input
        select={select}
        shrinkLabel={shrinkLabel}
        disabled={disabled}
        inputProps={
          isNumeric
            ? {
                type: 'number',
                startAdornment: currency && currencySymbols[currency],
              }
            : {}
        }
        label={label}
        placeholder={placeholder}
        multiline={multiline}
        {...input}
        onKeyPress={(e) => {
          if (
            isNumeric &&
            (['+', '-', 'e', 'E'].includes(e.key) || (!!currency && e.target.value.length >= 17))
          ) {
            e.preventDefault();
          }
        }}
        onKeyDown={(e) => {
          if (isNumeric && e.target.value - 1 < 0 && e.key === 'ArrowDown') {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          onChange && onChange(e);
          input.onChange(e);
        }}
        onWheel={(e) => {
          if (isNumeric && e.target.value - 1 < 0) {
            document.activeElement.blur();
          }
        }}
        error={(meta.touched || !touchBeforeError) && !!meta.error}
        helperText={
          (meta.touched || !touchBeforeError) && !!meta.error ? meta.error : defaultHelperText
        }
        autoFocus={autoFocus}
        {...(highlightOnFocus && { onFocus: (e) => e.target.select() })}
      >
        {children}
      </Input>);
    }}
  </Field>)
}

const SwitchField = ({ label, name, onChange, initialValue, trackingName }) => (
  <div className="createTransaction-switch">
    <Field name={name} type="checkbox" initialValue={initialValue}>
      {({ input }) => (
        <FormControlLabel
          control={
            <Switch
              data-tracking-name={trackingName}
              data-tracking-value={initialValue}
              data-tracking-subsection={trackingName}
              {...input}
              onChange={(...e) => {
                onChange && onChange(...e);
                input.onChange(...e);
              }}
            />
          }
          label={label}
        />
      )}
    </Field>
  </div>
);

const DatePickerField = ({
  name,
  validate,
  label,
  disablePast,
  minDate,
  maxDate,
  shrink,
  disabled,
  ignoreTimezone,
}) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => {
      let value = input.value || null;
      if (typeof input.value === 'string') {
        value = moment(input.value);
      }
      return (
        <DatePicker
          value={value}
          onChange={(date) => {
            const formattedDate =
              (ignoreTimezone && moment(date).utcOffset(0, true).startOf('day')) || date;
            input.onChange(formattedDate);
            input.onBlur(formattedDate); // Sets final-form field as touched
          }}
          label={label}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{
            textField: {
              shrink: shrink,
              fullWidth: true,
              onBlur: input.onBlur,
              error: meta.touched && !!meta.error,
              helperText: meta.touched && !!meta.error ? meta.error : null,
              margin: 'normal',
              InputLabelProps: {
                shrink: shrink,
              },
            },
          }}
          disabled={disabled}
        />
      );
    }}
  </Field>
);

/**
 * This SelectField is a more customized select component, with configurable Placeholder value.
 * For simple selections, use InputField component with a `select` prop instead.
 */

const SelectField = ({
  name,
  label,
  placeholder,
  children,
  initialValue,
  validate,
  disabled,
  renderSelectedOption,
  onChange,
  subscription,
  touchBeforeError = true,
  blurOnClose,
  handleOlark,
}) => {
  const { width } = useWindowDimensions();
  const isMobileView = width < TransactionConstants.DESKTOP_VIEW_WIDTH;

  return (
    <Field name={name} initialValue={initialValue} validate={validate} subscription={subscription}>
      {({ input, meta }) => (
        <FormControl fullWidth margin="normal">
          <InputLabel
            variant="outlined"
            error={meta.touched && !!meta.error}
            {...(placeholder && { shrink: true })}
          >
            <div className="materialUI-box-label">{label}</div>
          </InputLabel>
          <Select
            {...input}
            onChange={(...e) => {
              onChange && onChange(...e);
              input.onChange(...e);
            }}
            disabled={disabled}
            fullWidth
            displayEmpty
            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
            {...(placeholder &&
              renderSelectedOption && {
                // eslint-disable-next-line react/display-name
                renderValue: (selected) => {
                  if (!selected) {
                    return <span className="materialUI-placeholder">{placeholder}</span>;
                  }
                  return renderSelectedOption(selected);
                },
              })}
            error={(meta.touched || !touchBeforeError) && !!meta.error}
            onOpen={() => handleOlark && isMobileView && OlarkApi.toggleConsoleVisibility(false)}
            onClose={() => {
              blurOnClose && input.onBlur();
              handleOlark && isMobileView && OlarkApi.toggleConsoleVisibility(true);
            }}
          >
            {children}
          </Select>
          {(meta.touched || !touchBeforeError) && !!meta.error && (
            <FormHelperText error>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};

const HiddenField = ({ name, fieldProps }) => (
  <Field name={name} hidden {...fieldProps} component="input" />
);

const CustomPaper = (props) => <Paper elevation={8} {...props} />;

const AutocompleteField = ({
  name,
  validate,
  value,
  placeholder,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  disabled,
  getOptionDisabled,
  onChange,
  label,
  PaperComponent = CustomPaper,
  customInputEnabled,
  filterOptions,
  autoHighlight,
  autoSelect,
}) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <Autocomplete
        clearOnBlur
        value={value}
        placeholder={placeholder}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        isOptionEqualToValue={isOptionEqualToValue}
        disabled={disabled}
        PaperComponent={PaperComponent}
        onChange={(event, newValue, reason) => {
          let selectedValue = '';
          if (newValue && newValue.inputValue && customInputEnabled) {
            selectedValue = newValue.inputValue;
          }
          if (newValue) {
            selectedValue = newValue.value ? newValue.value : getCategoryValueFromOption(newValue);
          }
          onChange && onChange(selectedValue, reason);
          input.onChange(selectedValue);
        }}
        filterOptions={filterOptions}
        freeSolo={customInputEnabled}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="normal"
            error={meta.touched && !!meta.error}
            onBlur={input.onBlur}
            label={label}
            helperText={meta.touched && !!meta.error ? meta.error : null}
          />
        )}
        autoHighlight={autoHighlight}
        autoSelect={autoSelect}
      />
    )}
  </Field>
);

const InlineSelectField = ({
  name,
  label,
  children,
  onChange,
  getLabelFromValue,
  validate,
  disabled,
  initialValue,
}) => (
  <div className="createTransaction-check-inline">
    <div className="createTransaction-check-inline">{label}</div>
    <div className="createTransaction-check-inline">
      <Field name={name} validate={validate} initialValue={initialValue}>
        {({ input }) => (
          <Select
            disableUnderline
            {...input}
            variant="standard"
            onChange={(...e) => {
              onChange && onChange(...e);
              input.onChange(...e);
            }}
            renderValue={(selected) => (
              <span className="createTransaction-check-inline-value">
                {getLabelFromValue(selected)}
              </span>
            )}
            disabled={disabled}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              style: {
                width: 'auto',
              }
            }}
          >
            {children}
          </Select>
        )}
      </Field>
    </div>
  </div>
);

const PhoneNumberInputField = ({ name, disabled, placeholder, fieldIndex, country }) => {
  const [touched, setTouched] = useState(false);
  return (
    <Field name={name} disabled={disabled} validate={phoneValidate(fieldIndex)}>
      {({ input, meta }) => (
        <FormControl fullWidth>
          <PhoneNumberInput
            country={country}
            disableCountryGuess
            enableSearch
            disabled={disabled}
            placeholder={placeholder}
            {...input}
            inputClass={meta.error ? 'react-tel-input-invalid-number' : ''}
            onFocus={() => setTouched(true)}
          />
          {touched && !!meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

const Modal = ({ title, text, isOpen, onClose, fullWidth, children }) => (
  <Dialog maxWidth={'sm'} fullWidth={fullWidth} open={isOpen} onClose={onClose}>
    <Box className={'kyc-modal-box'}>
      {title ? <div className="kyc-modal-box-title"> {title} </div> : null}
      <div className="kyc-modal-box-body"> {text} </div>
      <div className="kyc-modal-box-buttons">{children}</div>
    </Box>
  </Dialog>
);

const ConfirmationModal = ({
  title,
  text,
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  fullWidth,
  isSubmitting,
}) => (
  <Modal title={title} text={text} isOpen={isOpen} onClose={onClose} fullWidth={fullWidth}>
    {isSubmitting ? (
      <div className="kyc-modal-box-buttons-spinner-wrapper">
        <Spinner />
      </div>
    ) : (
      <React.Fragment>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <SubmitButton muiVariant="contained" onClick={onConfirm} loading={isSubmitting}>
          Confirm
        </SubmitButton>
      </React.Fragment>
    )}
  </Modal>
);

const InformationModal = ({ title, text, isOpen, onClose, fullWidth }) => (
  <Modal title={title} text={text} isOpen={isOpen} onClose={onClose} fullWidth={fullWidth}>
    <Button variant="contained" onClick={onClose}>
      Close
    </Button>
  </Modal>
);

export {
  currencySymbols,
  AutocompleteField,
  InputField,
  SwitchField,
  DatePickerField,
  SelectField,
  HiddenField,
  InlineSelectField,
  PhoneNumberInputField,
  ConfirmationModal,
  InformationModal,
};
