import React, { Fragment } from 'react';
import { useForm } from 'react-final-form';
import { capitalize, MenuItem, Divider } from '@mui/material';
import MediaQuery from 'react-responsive';
import QueryString from 'query-string';
import TransactionConstants from 'spa/constants/TransactionConstants';
import {
  compose,
  required,
  strRequired,
  inspectionPeriodValidate,
  maxChars,
} from 'spa/components/form/validate';
import { InputField } from 'spa/components/StartTransaction/Fields';

const CURRENCIES = ['usd', 'gbp', 'eur', 'aud', 'cad'];

const INSPECTION_PERIOD_HELPER_TEXT =
  'This is allowed time for a Buyer to inspect the items before funds are automatically released.';

const RoleField = ({ onChange, disabled, edit, preset, hasMultipleItems = false }) => (
  <InputField
    name="role"
    onChange={onChange}
    validate={required}
    disabled={disabled}
    select
    label="My role"
    initialValue={preset || 'buyer'}
  >
    {Object.values(TransactionConstants.TRANSACTION_ROLES).map((value) => (
      <MenuItem
        key={value}
        value={value}
        disabled={
          value === TransactionConstants.TRANSACTION_ROLES.BROKER &&
          (edit !== 0 || hasMultipleItems)
        }
      >
        {capitalize(value)}
      </MenuItem>
    ))}
  </InputField>
);

const NameField = ({ disabled, onBlur }) => (
  <InputField
    name="title"
    validate={compose([strRequired, maxChars(100, 'Transaction title')])}
    label={'Transaction title'}
    disabled={disabled}
    autoFocus
    onBlur={onBlur}
  />
);

const CurrencyField = ({ disabled, preset }) => (
  <InputField
    name="currency"
    validate={required}
    select
    label="Currency"
    initialValue={preset || 'usd'}
    disabled={disabled}
  >
    {CURRENCIES.map((value) => (
      <MenuItem key={value} value={value}>
        {value.toUpperCase()}
      </MenuItem>
    ))}
  </InputField>
);

const InspectionPeriodField = ({ disabled }) => (
  <InputField
    name="inspectionPeriod"
    validate={inspectionPeriodValidate}
    isNumeric
    disabled={disabled}
    label={'Inspection period (days)'}
    initialValue={'1'}
    defaultHelperText={
      <MediaQuery maxWidth={TransactionConstants.DESKTOP_VIEW_WIDTH - 1}>
        {INSPECTION_PERIOD_HELPER_TEXT}
      </MediaQuery>
    }
  />
);

const TransactionDisclosureField = ({ onChange, disabled, isDNH }) => (
  <InputField
    name="transactionDisclosure"
    initialValue="confidential"
    disabled={disabled}
    validate={required}
    select
    label={'Transaction disclosure'}
    onChange={onChange}
    touchBeforeError={false}
  >
    {(isDNH
      ? [
          {
            value: 'transparentToBuyerAndSeller',
            label: 'Transparent to Buyer and Seller',
          },
        ]
      : TransactionConstants.BROKER_DISCLOSURE
    ).map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </InputField>
);

const TransactionInfo = ({ values, edit, submitting, onTitleBlur }) => {
  const form = useForm();
  const onRoleChange = (e) => {
    form.change('brokerCommissionPayer', 'seller');
    form.change('escrowFeePayer', 'buyer');
    if ((values.role === 'broker' || values.role === 'seller') && e.target.value === 'buyer') {
      form.change('buyerEmail', null);
      form.change('buyerPhone', null);
    }
    if ((values.role === 'broker' || values.role === 'buyer') && e.target.value === 'seller') {
      form.change('sellerEmail', null);
      form.change('sellerPhone', null);
    }
  };
  const onDisclosureChange = () => {
    form.change('brokerCommissionPayer', 'seller');
  };
  const { role, cur } = QueryString.parse(window.location.search);
  const isMilestone = values.items && values.items[0].isMilestone;
  const isDNH = values.items && values.items[0].isDNH;

  const onBlur = () => {
    if (onTitleBlur) {
      onTitleBlur(values.title);
    }
  };

  return (
    <div>
      <MediaQuery minWidth={TransactionConstants.DESKTOP_VIEW_WIDTH}>
        <div className="createTransaction-title">Start Transaction</div>
        <Divider variant="fullWidth" />
      </MediaQuery>
      <NameField disabled={submitting} onBlur={onBlur} />
      {values.role !== TransactionConstants.TRANSACTION_ROLES.BROKER ? (
        <div className="createTransaction-inline-fields-container">
          <div className={'createTransaction-inline-field--narrow'}>
            <RoleField
              onChange={onRoleChange}
              disabled={submitting}
              hasMultipleItems={values.items && values.items.length > 1}
              edit={edit}
              preset={role}
            />
          </div>
          <div className={'createTransaction-inline-field--narrow'}>
            <CurrencyField disabled={submitting} preset={cur && cur.toLowerCase()} />
          </div>
          <div className={'createTransaction-inline-field--narrow'}>
            <InspectionPeriodField disabled={submitting || isMilestone} />
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="createTransaction-inline-fields-container">
            <div className="createTransaction-inline-field--half">
              <RoleField onChange={onRoleChange} disabled={submitting} edit={edit} preset={role} />
            </div>
            <div className={'createTransaction-inline-field--half'}>
              <CurrencyField disabled={submitting} preset={cur && cur.toLowerCase()} />
            </div>
          </div>
          <div className="createTransaction-inline-fields-container">
            <div className={'createTransaction-inline-field--half'}>
              <InspectionPeriodField disabled={submitting} />
            </div>
            <div className={'createTransaction-inline-field--half'}>
              <TransactionDisclosureField
                onChange={onDisclosureChange}
                edit={edit}
                disabled={submitting}
                isDNH={isDNH}
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TransactionInfo;
