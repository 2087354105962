import React, { useState } from 'react';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import { Button } from '@mui/material';
import QueryString from 'query-string';
import {
  WhatsappShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
} from 'react-share';
import TransactionConstants from 'spa/constants/TransactionConstants';
import { LabeledAvatar } from 'spa/components/MaterialUI';
import { customerKyc1VerifiedSelector } from 'spa/selectors/CustomerSelectors';
import { urlfor } from '../../../utils/filters';

const { V3_FORM_NAME } = TransactionConstants;

const CreateTransactionSuccess = ({ transaction, isKyc1Verified }) => {
  const { id, title, role, token } = transaction;
  const otherPartyRole = {
    buyer: 'Seller',
    seller: 'Buyer',
    broker: 'Buyer and Seller',
  }[role];
  const iconsLocation = '../../../../build/images/global/icons';
  const transactionUrl = token
    ? `${window.config.www_base_url}/checkout?token=${token}`
    : `${window.config.www_base_url}/log-in?tid=${id}`;

  const fbAppId = window.config.fb_app_id;
  const shareMessage = `I’ve started a transaction titled ${title}. Please visit this link to agree to the terms of the transaction:`;

  const copyButtonDefault = 'Copy link';
  const copyButtonCopied = 'Copied!';
  const [copyButtonText, setCopyButtonText] = useState(copyButtonDefault);
  const handleCopyLink = () => {
    navigator.clipboard.writeText(transactionUrl);
    setCopyButtonText(copyButtonCopied);
    setTimeout(() => setCopyButtonText(copyButtonDefault), 2000);
  };

  const sellerNoBuyer = role === 'seller' && token;
  const buttonText = sellerNoBuyer ? 'Return to my transactions' : 'View transaction';

  const handleViewTransaction = () => {
    let redirectUrl = '';
    if (sellerNoBuyer) {
      redirectUrl = `${window.config.www_base_url}/transactions`;
      if (!isKyc1Verified) {
        const queryStr = {
          success: 'transaction',
          tid: id,
        };
        redirectUrl = `${urlfor('.verify-tier-1-buyer')}?${QueryString.stringify(queryStr)}`;
      }
    } else {
      redirectUrl = transactionUrl;
    }
    window.location = redirectUrl;
  };

  return (
    <div className="createTransaction">
      <div className="section-container createTransaction-successPage-container">
        <div className="createTransaction-successPage-title">Transaction Created!</div>
        <div className="createTransaction-successPage-content">
          {`Your transaction has been created, waiting for both parties to agree. Share the transaction
          via the URL or QR code so that the ${otherPartyRole} can agree to the terms.`}
        </div>
        <div
          className="createTransaction-successPage-container--inner"
          data-tracking-section={V3_FORM_NAME}
          data-tracking-subsection="URLShare"
        >
          <QRCode size={184} value={transactionUrl} includeMargin />
          <div className="createTransaction-successPage-share">
            <div className="createTransaction-successPage-header">Share</div>
            <div className="createTransaction-successPage-share-icons">
              <WhatsappShareButton
                url={transactionUrl}
                title={shareMessage}
                data-tracking-name="Whatsapp"
              >
                <LabeledAvatar
                  label="Whatsapp"
                  alt="whatsapp"
                  src={`${iconsLocation}/whatsapp.svg`}
                />
              </WhatsappShareButton>
              <EmailShareButton url={transactionUrl} body={shareMessage} data-tracking-name="email">
                <LabeledAvatar label="Email" alt="email" src={`${iconsLocation}/email.svg`} />
              </EmailShareButton>
              <FacebookMessengerShareButton
                url={transactionUrl}
                appId={fbAppId}
                data-tracking-name="Facebook Messenger"
              >
                <LabeledAvatar
                  label="Messenger"
                  alt="messenger"
                  src={`${iconsLocation}/messenger.svg`}
                />
              </FacebookMessengerShareButton>
              <TwitterShareButton
                url={transactionUrl}
                title={shareMessage}
                data-tracking-name="Twitter"
              >
                <LabeledAvatar label="Twitter" alt="twitter" src={`${iconsLocation}/twitter.svg`} />
              </TwitterShareButton>
            </div>
          </div>
          <div className="createTransaction-successPage-url">
            <div className="createTransaction-successPage-header">URL</div>
            <div className="createTransaction-successPage-url-box">
              <a className="createTransaction-successPage-url-link" href={transactionUrl}>
                {transactionUrl}
              </a>
              <Button
                onClick={handleCopyLink}
                classes={{
                  root: 'createTransaction-successPage-url-button',
                }}
                data-tracking-name="Copy Link"
              >
                {copyButtonText}
              </Button>
            </div>
          </div>
        </div>
        <Button
          fullWidth
          type="button"
          variant="contained"
          color="primary"
          size="large"
          classes={{
            root: 'createTransaction-successPage-button',
          }}
          onClick={handleViewTransaction}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isKyc1Verified: customerKyc1VerifiedSelector(state),
});

export default connect(mapStateToProps)(CreateTransactionSuccess);
