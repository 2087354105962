import React from 'react';
import { push } from 'connected-react-router';
import { isArray } from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { reduxForm, formValues, getFormSyncErrors, getFormMeta } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { Turnstile } from './Turnstile';
import { AgreeTerms } from 'spa/containers/CreateAPIKeys';
import A from 'spa/components/A';
import { formLoginSignup } from 'spa/actions/AuthenticationActions';
import { authenticateCustomer } from 'spa/actions/CheckoutActions';
import { prefillSelector } from 'spa/selectors/AuthenticationSelectors';
import { FormError } from 'spa/components/form';
import Icon from 'spa/components/Icon';
import { Spinner } from 'spa/components/Indicators';
import { ContactDetailsFieldset } from 'spa/components/ContactDetails';
import AuthenticationConstants from 'spa/constants/AuthenticationConstants';
import { asyncValidate } from '../CheckoutDetails/validate';
import LoginFieldset from './LoginFieldset';
import SignupFieldset from './SignupFieldset';
import { gettext } from '../../../utils/filters';
import { urlFor } from '../../../routeConfig';

class AuthenticationForm extends React.Component {
  constructor(props) {
    super(props);
    this.renderLoginForm = this.renderLoginForm.bind(this);
    this.renderSignupForm = this.renderSignupForm.bind(this);
    this.renderPasswordSuggestions = this.renderPasswordSuggestions.bind(this);
    this.toggleFormMode = this.toggleFormMode.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.navigateToForgotPassword = this.navigateToForgotPassword.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { mode, loginPage } = this.props;
    const query = window.location.search;
    const {
      MODE_LOGIN,
      INTEGRATIONS_LOGIN_PAGE,
      DEFAULT_LOGIN_PAGE,
      INTEGRATIONS_LANDING_LOGIN_PAGE,
    } = AuthenticationConstants;
    if (mode === prevProps.mode) {
      return;
    }
    if (mode === MODE_LOGIN) {
      if (loginPage === DEFAULT_LOGIN_PAGE) {
        window.history.pushState(null, null, query ? `/log-in${query}` : '/log-in');
        document.title = 'Login to your Escrow account';
      } else if (loginPage === INTEGRATIONS_LOGIN_PAGE) {
        window.history.pushState(
          null,
          null,
          query ? `/integrations/login${query}` : '/integrations/login'
        );
        document.title = 'Escrow.com';
      } else if (loginPage === INTEGRATIONS_LANDING_LOGIN_PAGE) {
        document.title = 'Login to your Escrow account';
      }
    } else if (loginPage === DEFAULT_LOGIN_PAGE) {
      window.history.pushState(null, null, query ? `/signup-page${query}` : '/signup-page');
      document.title = 'Sign Up: Create an Escrow Account';
    } else if (loginPage === INTEGRATIONS_LOGIN_PAGE) {
      window.history.pushState(
        null,
        null,
        query ? `/integrations/signup${query}` : '/integrations/signup'
      );
      document.title = 'Escrow.com';
    } else if (loginPage === INTEGRATIONS_LANDING_LOGIN_PAGE) {
      document.title = 'Sign Up: Create an Escrow Account';
    }
  }

  toggleFormMode() {
    const { mode, setMode, clearAsyncError } = this.props;
    const { MODE_SIGNUP, MODE_LOGIN } = AuthenticationConstants;

    if (mode === MODE_SIGNUP) {
      setMode(MODE_LOGIN);
    } else {
      setMode(MODE_SIGNUP);
    }
    clearAsyncError('username');
  }

  navigateToForgotPassword(event) {
    event.preventDefault();
    this.props.forgotPassword();
  }

  renderLoginForm() {
    const { submitting, submitSucceeded, loading, form, loginPage, lockEmailAddress, change } =
      this.props;
    const disabled = submitting || submitSucceeded || loading;
    const handleTurnstileSuccess = (token) => {
      change('turnstileResponse', token);
    };
    if (loginPage === AuthenticationConstants.INTEGRATIONS_LOGIN_PAGE) {
      return (
        <div className="integrationsAuth-form">
          {this.renderHeader()}
          <LoginFieldset
            formName={form}
            lockEmailAddress={lockEmailAddress}
            formStyle={AuthenticationConstants.INTEGRATIONS_LOGIN_PAGE}
          />
          <div className="customInput-footer">
            <a
              role="link" // static elements with event handlers require a role
              onClick={this.navigateToForgotPassword}
              className="customInput-link"
              draggable="true"
              tabIndex={0} // link role needs to be focusable
            >
              {gettext('Forgot your password?')}
            </a>
          </div>
          <div className="auth_turnstile integrations_turnstile">
            <Turnstile action="login-form" onSuccess={handleTurnstileSuccess} />
          </div>
          <button
            type="submit"
            className="btn btn--secondary"
            data-e2e-target="login-form-submit"
            disabled={disabled}
            data-tracking-name="login"
          >
            {disabled ? <Spinner /> : gettext('Sign in')}
          </button>
        </div>
      );
    }
    return (
      <div className="checkout-card-content signupForm-card checkout-card-content--auth">
        <div>
          {this.renderHeader()}
          <LoginFieldset formName={form} lockEmailAddress={lockEmailAddress} />
        </div>
        <div className="auth_turnstile">
          <Turnstile action="login-form" onSuccess={handleTurnstileSuccess} />
        </div>
        <div className="checkout-card-btn-container">
          <button
            type="submit"
            className="checkout-card-btn checkout-card-btn--auth btn btn--secondary"
            data-e2e-target="checkout-login-btn"
            disabled={disabled}
            data-tracking-name="login"
          >
            {disabled ? <Spinner /> : gettext('Secure Login')}
          </button>
          <a
            role="link" // static elements with event handlers require a role
            className="checkout-card-btn-link"
            onClick={this.navigateToForgotPassword}
            tabIndex={0} // link role needs to be focusable
          >
            {gettext('Recover your password')}
          </a>
        </div>
      </div>
    );
  }

  renderHeader() {
    const { partner, mode, loginPage } = this.props;
    const isLoginMode = mode === AuthenticationConstants.MODE_LOGIN;
    const heading = isLoginMode ? 'Login to Escrow.com' : 'Create an account on Escrow.com';
    const linkText = isLoginMode ? 'Sign up' : 'Sign in';
    let subheading = isLoginMode ? "Don't have an account?" : 'Already have an account';
    const hashPattern = /[A-Za-z0-9]{30}/;
    switch (loginPage) {
      case AuthenticationConstants.INTEGRATIONS_LOGIN_PAGE:
        return (
          <React.Fragment>
            <legend className="integrationsAuth-form-legend">{gettext(heading)}</legend>
            <span className="integrationsAuth-switcher">
              {gettext(subheading)}
              <a
                role="button"
                tabIndex={0}
                onClick={this.toggleFormMode}
                className="integrationsAuth-switcher-link"
              >
                {' '}
                {gettext(linkText)}
              </a>
            </span>
          </React.Fragment>
        );
      case AuthenticationConstants.CHECKOUT_LOGIN_PAGE:
        if (isLoginMode) {
          subheading =
            !partner || !partner.name || hashPattern.test(partner.name)
              ? 'The most trusted, licensed online escrow service in the world.'
              : `Escrow.com is a trusted, and licensed online escrow service recommended by ${partner.name} to protect buyers and sellers.`;
        } else {
          subheading =
            !partner || !partner.name || hashPattern.test(partner.name)
              ? 'The most trusted, licensed online escrow service in the world.'
              : `Escrow.com is a trusted, and licensed online escrow service recommended by ${partner.name} to protect buyers and sellers.`;
        }
        return (
          <React.Fragment>
            <h2 className="checkout-page-header checkout-page-header--auth">{heading}</h2>
            <div className="checkout-page-subheader checkout-page-subheader--auth">
              {subheading}
            </div>
          </React.Fragment>
        );
      default:
        return <h2 className="checkout-heading">{gettext(heading)}</h2>;
    }
  }

  renderTermsStatement() {
    const country = window.config.geoip_country;
    // users in Australia should see extra links in the Terms and Conditions statement
    if (country === 'AUS') {
      return (
        <div className="signupForm-agreement">
          {gettext("By registering you agree to the Escrow.com's ")}
          <A
            link={{
              type: 'internal',
              route: '/legal',
              newTab: true,
            }}
            className="checkout-terms-link"
          >
            {gettext('Terms of Using the Escrow Platform')}
          </A>
          {gettext(', ')}
          <A
            link={{
              type: 'internal',
              route: '/legal',
              newTab: true,
            }}
            className="checkout-terms-link"
          >
            {gettext('Combined Financial Services Guide (FSG)')}
          </A>
          {gettext(' and ')}
          <A
            link={{
              type: 'internal',
              route: '/legal',
              newTab: true,
            }}
            className="checkout-terms-link"
          >
            {gettext('Product Disclosure Statement (PDS)')}
          </A>
          {gettext(' and ')}
          <A
            link={{
              type: 'internal',
              route: '/legal',
              newTab: true,
            }}
            className="checkout-terms-link"
          >
            {gettext('Privacy Policy')}
          </A>
          {gettext('. You consent to receiving these policies online.')}
        </div>
      );
    }

    return (
      <div className="signupForm-agreement">
        {gettext("By registering you agree to the Escrow.com's ")}
        <A
          link={{
            type: 'internal',
            route: '/legal',
            newTab: true,
          }}
          className="checkout-terms-link"
        >
          {gettext('Terms of Using the Escrow Platform')}
        </A>
        {gettext(' and ')}
        <A
          link={{
            type: 'internal',
            route: '/legal',
            newTab: true,
          }}
          className="checkout-terms-link"
        >
          {gettext('Privacy Policy')}
        </A>
      </div>
    );
  }

  renderPasswordSuggestions() {
    const { loginPage } = this.props;
    const { password: passwordValidationError } = this.props.formSyncErrors;
    const { password } = this.props.formFields;
    const suggestions = [
      gettext('At least 7 characters'),
      gettext('At least one UPPER case and one lower case character'),
      gettext('At least one number or special character'),
    ];
    const isIntegrationStyle = loginPage === AuthenticationConstants.INTEGRATIONS_LOGIN_PAGE;
    return (
      <div
        className={classnames({
          'signupForm-suggestion': !isIntegrationStyle,
          'integrationsAuth-helper': isIntegrationStyle,
        })}
      >
        <div
          className={classnames({
            'signupForm-suggestion-list': !isIntegrationStyle,
          })}
        >
          <h3
            className={classnames('signupForm-suggestion-title', {
              'is-success': password && password.visited && !passwordValidationError,
            })}
          >
            {gettext('Strong passwords have:')}
          </h3>
          <ul className="signupForm-suggestion-list">
            {suggestions.map((s) => (
              <li
                className={classnames('signupForm-suggestion-item', {
                  'is-invalid':
                    password &&
                    password.visited &&
                    isArray(passwordValidationError) &&
                    passwordValidationError.includes(s),
                  'is-success':
                    password &&
                    password.visited &&
                    ((isArray(passwordValidationError) && !passwordValidationError.includes(s)) ||
                      !passwordValidationError),
                })}
                key={s}
              >
                <Icon name="tick" className="signupForm-suggestion-icon" />
                {s}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  renderSignupForm() {
    const {
      form,
      lockEmailAddress,
      submitting,
      submitSucceeded,
      enableSignupCustomerDetails,
      loading,
      loginPage,
      change,
    } = this.props;
    const { INTEGRATIONS_LOGIN_PAGE, CHECKOUT_LOGIN_PAGE } = AuthenticationConstants;
    const disabled = submitSucceeded || submitting || loading;
    const handleTurnstileSuccess = (token) => {
      change('turnstileResponse', token);
    };
    if (loginPage === INTEGRATIONS_LOGIN_PAGE) {
      return (
        <div className="integrationsAuth-form">
          {this.renderHeader()}
          <SignupFieldset
            formName={form}
            lockEmailAddress={lockEmailAddress}
            formStyle={INTEGRATIONS_LOGIN_PAGE}
          />
          {enableSignupCustomerDetails && this.renderSignupCustomerDetails()}
          {this.renderPasswordSuggestions()}
          <div className="auth_turnstile integrations_turnstile integrations_turnstile-signup">
            <Turnstile action="signup-form" onSuccess={handleTurnstileSuccess} />
          </div>
          <button
            type="submit"
            className="btn btn--secondary integrationsAuth-btn"
            data-e2e-target="signup-form-submit"
            disabled={disabled}
            data-tracking-name="signup"
          >
            {disabled ? <Spinner /> : gettext('Register')}
          </button>
          <AgreeTerms />
        </div>
      );
    }
    return (
      <div className="checkout-card-content signupForm-card checkout-card-content--auth">
        <div>
          {this.renderHeader()}
          <SignupFieldset formName={form} lockEmailAddress={lockEmailAddress} />
          {enableSignupCustomerDetails && this.renderSignupCustomerDetails()}
          {loginPage !== CHECKOUT_LOGIN_PAGE && this.renderPasswordSuggestions()}
          <div className="auth_turnstile">
            <Turnstile action="signup-form" onSuccess={handleTurnstileSuccess} />
          </div>
          <button
            type="submit"
            className="checkout-card-btn checkout-card-btn--auth btn btn--secondary"
            data-e2e-target="checkout-signup-btn"
            disabled={disabled}
            data-tracking-name="signup"
          >
            {disabled ? <Spinner /> : gettext('Sign Up')}
          </button>
          {this.renderTermsStatement()}
        </div>
      </div>
    );
  }

  renderSignupCustomerDetails() {
    return (
      <ContactDetailsFieldset
        formName={this.props.form}
        isCompany={this.props.isCompany}
        primaryPhoneCountry={this.props.primaryPhoneCountry}
        country={this.props.country}
        companyCountry={this.props.companyCountry}
      />
    );
  }

  renderFormSwitchButtons() {
    const { CHECKOUT_LOGIN_PAGE } = AuthenticationConstants;
    const { submitting, loading, mode, loginPage } = this.props;
    const disabled = submitting || loading;
    if (mode === AuthenticationConstants.MODE_LOGIN) {
      if (loginPage === CHECKOUT_LOGIN_PAGE) {
        return (
          <div className="checkout-switcher checkout-card-content checkout-card-content--small">
            <a
              role="button"
              tabIndex={0}
              className="checkout-card-secondaryAction"
              data-e2e-target="checkout-switch-signup-btn"
              onClick={this.toggleFormMode}
              data-tracking-name="signup"
              data-tracking-label="switch-auth-form"
            >
              {gettext('Create an Account')}
            </a>
          </div>
        );
      }
      return (
        <div className="checkout-switcher checkout-card-content signupForm-card">
          <div className="checkout-card-separator">- {gettext('OR')} -</div>
          <button
            className="checkout-card-btn checkout-card-btn--auth btn btn--secondary btn--hollow"
            data-e2e-target="checkout-switch-signup-btn"
            data-tracking-name="signup"
            data-tracking-label="switch-auth-form"
            onClick={this.toggleFormMode}
            disabled={disabled}
          >
            {gettext('Register an Account')}
          </button>
        </div>
      );
    } else if (mode === AuthenticationConstants.MODE_SIGNUP) {
      return (
        <div className="checkout-switcher checkout-card-content checkout-card-content--small">
          <a
            role="button"
            tabIndex={0}
            className="checkout-card-secondaryAction"
            data-e2e-target="checkout-switch-login"
            data-tracking-name="login"
            data-tracking-label="switch-auth-form"
            onClick={this.toggleFormMode}
          >
            {gettext('Login to escrow.com')}
          </a>
        </div>
      );
    }
    return null;
  }

  render() {
    let fieldset = null;
    const { INTEGRATIONS_LOGIN_PAGE, MODE_LOGIN, MODE_SIGNUP, CHECKOUT_LOGIN_PAGE } =
      AuthenticationConstants;
    const { loginPage, mode, submitFailed, error, form } = this.props;
    if (mode === MODE_LOGIN) {
      fieldset = this.renderLoginForm();
    } else if (mode === MODE_SIGNUP) {
      fieldset = this.renderSignupForm();
    }
    const isIntegrationPage = loginPage === INTEGRATIONS_LOGIN_PAGE;
    const formSubmitRoutine =
      loginPage === CHECKOUT_LOGIN_PAGE ? authenticateCustomer : formLoginSignup;

    return (
      <div>
        {submitFailed && error && (
          <div
            className={classnames({
              'checkout-authError': !isIntegrationPage,
              'integrationsAuth-error': isIntegrationPage,
            })}
          >
            <FormError error={error} />
          </div>
        )}
        <form
          method="post"
          name={form}
          onSubmit={this.props.handleSubmit(bindRoutineToReduxForm(formSubmitRoutine))}
        >
          {fieldset}
        </form>
        {!isIntegrationPage && this.renderFormSwitchButtons()}
      </div>
    );
  }
}

const signupAsyncValidate = (values, dispatch, props) => {
  if (props.mode === AuthenticationConstants.MODE_LOGIN) {
    return Promise.resolve();
  }
  return asyncValidate(values, dispatch, props);
};

AuthenticationForm.propTypes = {
  mode: PropTypes.oneOf([AuthenticationConstants.MODE_LOGIN, AuthenticationConstants.MODE_SIGNUP]),
  setMode: PropTypes.func.isRequired,
  loginPage: PropTypes.string.isRequired,
  enableSignupCustomerDetails: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const initialValues = {
    ...prefillSelector(state),
    ...ownProps.initialValues,
  };
  initialValues.username = initialValues.username || (window.js_context || {}).username;
  const lockEmailAddress =
    ownProps.loginPage === AuthenticationConstants.CHECKOUT_LOGIN_PAGE
      ? ownProps.initialValues.lock_email
      : ownProps.lockEmailAddress || (window.js_context || {}).lock_email;
  return {
    initialValues: initialValues,
    lockEmailAddress: lockEmailAddress,
    formSyncErrors: getFormSyncErrors(AuthenticationConstants.AUTHENTICATION_FORM)(state),
    formFields: getFormMeta(AuthenticationConstants.AUTHENTICATION_FORM)(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: () => dispatch(push(urlFor('forgot_password_page'))),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: AuthenticationConstants.AUTHENTICATION_FORM,
    enableReinitialize: true,
    asyncValidate: signupAsyncValidate,
    asyncChangeFields: ['username'],
  })(
    formValues(
      'username',
      'primaryPhoneCountry',
      'country',
      'companyCountry',
      'isCompany',
      'turnstileResponse'
    )(AuthenticationForm)
  )
);
