import React, { Fragment } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import TransactionStepper from '../../features/transaction/components/TransactionStepper';
import TrackingActions from '../../../actions/TrackingActions';
import { formatUTCtoSentence } from '../../../utils/date';

const TransactionAction = ({ data, loading, error }) => {
  const handleViewTransaction = (ctaType) => {
    TrackingActions.track({
      event: 'escrow_user_action',
      section: 'transaction-progress-action-section',
      name: `transaction-progress-${ctaType}-cta`,
      action: 'click',
      value: `click-${ctaType}-cta`,
    });
    const redirectUrl = `${window.config.www_base_url}/log-in?tid=${data.transaction_details.id}`;
    window.location = redirectUrl;
  };

  const progressSteps =
    !error && !loading && data
      ? data.progress_steps.map((s) => ({
          title: s.title,
          subtitle: s.last_updated ? `Last updated: ${formatUTCtoSentence(s.last_updated)}` : null,
          inProgress: s.in_progress,
          completed: s.complete,
        }))
      : [];

  return (
    <Fragment>
      {!loading && !error && data && (
        <div className="transactionProgress-container section-container">
          <Card className="transactionProgress-card">
            <CardContent className="transactionProgress-card-action">
              <h3 className="transactionProgress-card-title">{data.current_action.title}</h3>
              <p className="transactionProgress-card-content-description">
                {data.current_action.description}
              </p>
              {data.current_action.time_estimate && (
                <p className="transactionProgress-card-content-subcontent">
                  Estimated time to complete: {data.current_action.time_estimate}
                </p>
              )}
              {data.current_action.is_passive_cta ? (
                <div className="transactionProgress-card-content-buttonContainer">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => handleViewTransaction('passive')}
                  >
                    {data.current_action.cta_text}
                  </Button>
                </div>
              ) : (
                <div className="transactionProgress-card-content-buttonContainer">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => handleViewTransaction('active')}
                  >
                    {data.current_action.cta_text}
                  </Button>
                </div>
              )}
            </CardContent>
            {progressSteps.length > 0 && (
              <div className="transactionProgress-card-stepper">
                <TransactionStepper steps={progressSteps} />
              </div>
            )}
          </Card>
        </div>
      )}
    </Fragment>
  );
};

export default TransactionAction;
