/* eslint-disable no-nested-ternary */
import React, { Fragment, useCallback, useEffect } from 'react';
import { Button, Divider, createFilterOptions } from '@mui/material';
import { Field, useForm } from 'react-final-form';
import QueryString from 'query-string';
import TransactionConstants from 'spa/constants/TransactionConstants';
import {
  compose,
  maxChars,
  required,
  strRequired,
  domainValidate,
  priceValidate,
  validateItemDescription,
  stripDomainPrefixes,
} from 'spa/components/form/validate';
import { formatPrice } from 'spa/components/form/format';
import {
  GMAdditionalInfoInput,
  MVAdditionalInfoInput,
} from 'spa/components/StartTransaction/TransactionItem/AdditionalFields';
import {
  AutocompleteField,
  InputField,
  SwitchField,
  HiddenField,
} from 'spa/components/StartTransaction/Fields';
import { useWindowDimensions } from 'spa/hooks';
import { getCategoryOptionFromValue } from 'spa/components/StartTransaction/util';
import { getPrefillFields } from 'spa/components/StartTransaction/util';
import { CheckboxField } from 'spa/components/CustomerVerification/V2/Fields';
import { useTheme } from '@mui/material/styles';
import BulkInput from './BulkInput';

const {
  TRANSACTION_TYPES,
  TRANSACTION_TYPES_CATEGORY,
  TRANSACTION_TYPES_DISPLAY_NAME,
  DESKTOP_VIEW_WIDTH,
  V3_FORM_NAME,
} = TransactionConstants;

/**
 * Item categories that don't necessarily require shipping.
 * @see {@link https://phabricator.tools.flnltd.com/T274305}
 */
const NO_SHIPPING_CATEGORIES = new Set([
  'ip_addresses',
  'social_media_accounts',
  'services',
  'tickets_and_events',
]);

const TransactionItemInputVariant = ({
  name,
  category,
  fieldName,
  index,
  length,
  onSubmit,
  values,
  formValues,
  onRemove,
  onAdd,
  error,
  disabled,
  setDisabled,
  bulkUploadCount,
  setBulkUploadCount,
  shouldPrefill,
  onPrefilled,
  titleToFill,
  onTitleFilled,
}) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const form = useForm();
  const query = QueryString.parse(window.location.search);

  const { role, currency, title } = formValues;
  const updateItem = useCallback(
    (newValues) => {
      Object.entries(newValues).forEach(([key, value]) => form.change(`${name}.${key}`, value));
    },
    [form, name]
  );
  const firstItemIsMilestone = form.getFieldState('items').value[0].isMilestone;
  const firstItemCategory = form.getFieldState('items').value[0].category;

  let itemType = form.getFieldState('items').value[0].itemType;
  const categoryDisabled = index > 0 && itemType === TRANSACTION_TYPES.DOMAIN_NAME;
  const availableCategories =
    length === 1
      ? Object.entries(TRANSACTION_TYPES_CATEGORY).reduce(
          (result, value) => [...result, ...value[1]],
          []
        )
      : TRANSACTION_TYPES_CATEGORY[itemType];

  useEffect(() => {
    if (index > 0) {
      form.change(`${name}.itemType`, itemType);
    }
    if (categoryDisabled) {
      form.change(`${name}.category`, firstItemCategory);
    }
  }, [categoryDisabled, firstItemCategory, form, index, itemType, name]);

  const displayedCategory =
    itemType === TRANSACTION_TYPES.DOMAIN_NAME ? firstItemCategory : category;

  const filter = createFilterOptions();
  const customInputEnabled = true;

  const ItemNameField = (
    <InputField
      name={`${name}.name`}
      validate={compose([strRequired, maxChars(100, 'Item name')])}
      label="Item name"
      disabled={disabled}
      autoFocus={!shouldPrefill}
    />
  );

  const shouldShowDomainProvidedCheckbox =
    (itemType === TRANSACTION_TYPES.DOMAIN_NAME
      || itemType === TRANSACTION_TYPES.DOMAIN_NAME_HOLDING);
  const explicitlyNoDomainProvided = !form.getFieldState('items').value[index].includeDomains;
  const shouldShowDomainNameFields = !explicitlyNoDomainProvided && shouldShowDomainProvidedCheckbox;
  let itemDomains = form.getFieldState('items').value[index].domainNames;
  if (!itemDomains) {
    itemDomains = [""];
  }
  const numItemDomains = itemDomains.length;

  useEffect(() => {
    if (shouldPrefill) {
      updateItem(getPrefillFields(query));
      onPrefilled();
    }
  }, [onPrefilled, query, shouldPrefill, updateItem]);

  useEffect(() => {
    if (shouldShowDomainNameFields && titleToFill) {
      if (!itemDomains[0] && !domainValidate(titleToFill)) {
        form.mutators.update(`${name}.domainNames`, 0, titleToFill);
      }
      if (onTitleFilled) {
        onTitleFilled();
      }
    }
  }, [titleToFill, form, name, itemDomains,
    shouldShowDomainNameFields, onTitleFilled]);

  const stripAllDomainPrefixes = (num) => {
    if (itemDomains[num]) {
      const newValue = stripDomainPrefixes(itemDomains[num]);
      if (newValue !== itemDomains[num]) {
        itemDomains[num] = newValue;
        form.mutators.update(`${name}.domainNames`, num, newValue);
      }
    }
  }

  useEffect(() => {
    if (!shouldShowDomainNameFields) {
      return;
    }
    let prospectiveName = itemDomains.filter((s) => s && s.length > 0).join(", ");
    if (prospectiveName.length >= 100) {
      prospectiveName = `${prospectiveName.substring(0, 97)}...`;
    }
    form.change(`${name}.name`, prospectiveName);
  }, [itemDomains, form, name, shouldShowDomainNameFields]);


  function DomainNameField(num) {
    return (
      <Fragment>
        <InputField
          name={`${name}.domainNames[${num}]`}
          validate={domainValidate}
          onBlur={() => { stripAllDomainPrefixes(num) }}
          label="Domain name"
          disabled={disabled}
          placeholder="example.com"
          shrinkLabel
          sx={{ marginBottom: 0 }}
          autoFocus={!!title}
          highlightOnFocus
          onFocus={() => { if (onTitleFilled) onTitleFilled() }}
          initialValue={num < numItemDomains ? itemDomains[num] : ""}
        />
      </Fragment>
    );
  }

  const ItemPriceField = (
    <InputField
      name={`${name}.price`}
      validate={priceValidate}
      disabled={disabled}
      isNumeric
      currency={currency}
      label={currency ? `Price (${currency.toUpperCase()})` : 'Price'}
      initialValue="0.00"
      format={formatPrice}
      formatOnBlur
      highlightOnFocus
    />
  );

  const BrokerCommissionField = (
    <InputField
      name={`${name}.brokerCommission`}
      validate={priceValidate}
      disabled={disabled}
      isNumeric
      currency={currency}
      initialValue="0.00"
      format={formatPrice}
      formatOnBlur
      highlightOnFocus
      label={currency ? `Broker commission (${currency.toUpperCase()})` : 'Broker commission'}
    />
  );

  const CSVBulkUpload = !values.isDNH &&
    itemType === TRANSACTION_TYPES.DOMAIN_NAME &&
    bulkUploadCount === 0 &&
    !!firstItemCategory &&
    values.new && (
      <BulkInput
        width={width}
        broker={role === 'broker'}
        category={firstItemCategory}
        name={fieldName}
        disabled={disabled}
        currentIndex={index}
        onSuccess={() => {
          setDisabled(false);
          setBulkUploadCount(bulkUploadCount + 1);
          onSubmit();
        }}
        onFailure={() => setDisabled(false)}
        onSubmit={() => setDisabled(true)}
      />
    );

  const ItemCancelButton =
    length > 1 &&
    (values.new ? (
      <Button
        onClick={onRemove}
        variant="text"
        color="primary"
        fullWidth={width < DESKTOP_VIEW_WIDTH}
        size="large"
      >
        Cancel
      </Button>
    ) : (
      <Button
        onClick={onRemove}
        variant="outlined"
        color="error"
        fullWidth={width < DESKTOP_VIEW_WIDTH}
        size="large"
      >
        Remove item
      </Button>
    ));

  const shouldHideAdditionalFields = () => {
    const generalItemFields = [
      'new',
      'price',
      'name',
      'description',
      'itemType',
      'category',
      'shippingMethod',
      'shippingFeePayer',
    ];

    for (const [key, value] of Object.entries(values)) {
      const fieldValue = key === 'shippingFee' ? parseFloat(value) : value;
      if (!generalItemFields.includes(key) && !!fieldValue) {
        return false;
      }
    }

    const dropdownFields = ['shippingMethod', 'shippingFeePayer'];
    for (const field of dropdownFields) {
      const fieldState = form.getFieldState(`${name}.${field}`);
      if (fieldState && (fieldState.touched || fieldState.active)) {
        return false;
      }
    }

    return true;
  };

  const getItemTypeFromCateg = (selectedCategory) => {
    if (selectedCategory) {
      let transactionType = TRANSACTION_TYPES.GENERAL_MERCHANDISE;
      for (const [key, value] of Object.entries(TRANSACTION_TYPES_CATEGORY)) {
        const matches = value.filter((categ) => categ.value === selectedCategory);
        if (matches.length) {
          transactionType = key;
        }
      }
      return transactionType;
    }
  };

  const updateItemType = (newItemType) => {
    const prevItemType = itemType;
    form.change(`${name}.itemType`, newItemType);
    itemType = form.getFieldState('items').value[0].itemType;

    if (prevItemType === TRANSACTION_TYPES.DOMAIN_NAME) {
      updateItem({
        isDNH: false,
        paymentSchedule: null,
        dnsManagedBy: null,
        years: null,
        months: null,
        shippingMethod: null,
        shippingFee: null,
        firstPaymentDate: null,
        paymentFrequency: null,
      });
      if (firstItemCategory === 'website') {
        updateItem({ concierge: false });
      } else {
        updateItem({ domainNames: null });
      }
    } else if (itemDomains && itemDomains.length > 0 && itemDomains[0]) {
      updateItem({
        name: itemDomains[0],
      });
    } else {
      form.mutators.update(`${name}.domainNames`, 0,
        form.getFieldState("items").value[0].name
      );
    }
    if (prevItemType === TRANSACTION_TYPES.MOTOR_VEHICLE) {
      updateItem({
        year: null,
        month: null,
        vin: null,
        make: null,
        model: null,
        odometer: null,
        shippingMethod: null,
        shippingFeePayer: 'seller',
        shippingFee: null,
        titleCollection: null,
        lienHolder: null,
      });
    }
    if (prevItemType === TRANSACTION_TYPES.GENERAL_MERCHANDISE) {
      updateItem({
        isMilestone: false,
        shippingMethod: null,
        shippingFeePayer: 'seller',
        shippingFee: null,
        itemInspectionPeriod: null,
      });
    }
    if (newItemType === TRANSACTION_TYPES.MOTOR_VEHICLE) {
      updateItem({ shippingMethod: 'cargo_shipping' });
    }
    if (newItemType === TRANSACTION_TYPES.GENERAL_MERCHANDISE) {
      updateItem({ shippingMethod: 'standard_shipping' });
    }
  };

  const onCategoryChange = (newCategory, reason) => {
    if (reason === 'clear' && length === 1 && shouldHideAdditionalFields()) {
      updateItem({ itemType: null });
    }
    const newItemType = getItemTypeFromCateg(newCategory);
    if (newItemType && newItemType !== itemType) {
      updateItemType(newItemType);
    }
    if (itemType === TRANSACTION_TYPES.DOMAIN_NAME && newCategory === 'website') {
      updateItem({
        isDNH: false,
        paymentSchedule: null,
        dnsManagedBy: null,
        years: null,
        months: null,
        firstPaymentDate: null,
        paymentFrequency: null,
      });
    }
    if (itemType === TRANSACTION_TYPES.MOTOR_VEHICLE) {
      updateItem({ shippingMethod: 'cargo_shipping' });
    }
    if (itemType === TRANSACTION_TYPES.GENERAL_MERCHANDISE) {
      updateItem({ shippingMethod: 'standard_shipping' });
    }
    if (NO_SHIPPING_CATEGORIES.has(newCategory)) {
      updateItem({ shippingMethod: 'no_shipping' });
    }
  };

  const onAddDomain = () => {
    form.mutators.push(`${name}.domainNames`, "");
  };

  const onRemoveDomain = (domainIndex) => {
    itemDomains.splice(domainIndex, 1);
    form.change(`${name}.domainNames`, []);
    form.mutators.concat(`${name}.domainNames`, itemDomains);
  };

  function addDomainButton(domainNum) {
    const styles = {
      color: theme.palette.secondaryLight.main,
      marginTop: 0,
      paddingTop: 0,
    };
    return (
      <Button
        onClick={onAddDomain}
        fullWidth={false}
        size="small"
        disabled={disabled || domainNum < numItemDomains - 1}
        sx={styles}
      >
        Add Another Domain
      </Button>
    );
  }

  function removeDomainButton(domainNum) {
    const styles = {
      color: theme.palette.error.main,
      marginTop: 0,
      paddingTop: 0,
    };
    return (
      <Button
        onClick={() => {
          onRemoveDomain(domainNum);
        }}
        fullWidth={false}
        size="small"
        disabled={disabled}
        sx={styles}
      >
        Remove
      </Button>
    );
  }

  return (
    <div key={name}>
      {index > 0 && (
        <div>
          <div className="createTransaction-subform--header">
            {values.new ? 'Add another item' : 'Update item'}
          </div>
          <Divider variant="fullWidth" />
        </div>
      )}
      <AutocompleteField
        name={`${name}.category`}
        customInputEnabled={customInputEnabled}
        validate={compose([required, maxChars(50, 'Item category')])}
        value={displayedCategory ? getCategoryOptionFromValue(displayedCategory) : ''}
        placeholder="Please select an item category"
        options={availableCategories}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;

          const customInput = inputValue.replace('Other: ', '');
          const inputInOptions = options.some((option) => inputValue === option.label);
          if (customInput !== '' && !inputInOptions) {
            filtered.push({ label: `Other: "${customInput}"`, value: customInput });
          }

          return filtered;
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disabled={categoryDisabled}
        onChange={onCategoryChange}
        label="Item category"
        autoHighlight
        autoSelect
      />
      {
        shouldShowDomainProvidedCheckbox && (
          <CheckboxField
            name={`${name}.includeDomains`}
            label="This item contains domain names only"
            disabled={disabled}
            initialValue
            onChange={(evt) => {
              if (evt.target.value) {
                form.change(`${name}.name`, itemDomains[0]);
              } else {
                form.change(`${name}.domainNames`, null);
              }
            }}
          />
        )
      }
      { !shouldShowDomainNameFields && (
        <div className="createTransaction-inline-fields-container">
              <Fragment>
                <div className="createTransaction-inline-field--half">{ItemNameField}</div>
                <div className="createTransaction-inline-field--half">{ItemPriceField}</div>
              </Fragment>
        </div>
      )}
      {
        shouldShowDomainNameFields &&
        (
          <Fragment>
            {Array.from(Array(numItemDomains).keys()).map((domainNum) => (
              <Fragment
                key={`${name}--domain--${domainNum}`}
              >
                <div className="createTransaction-inline-fields-container"
                     style={{marginBottom: "-6px"}}
                >
                  <div
                    className="createTransaction-inline-field--wide">{DomainNameField(domainNum)}
                  </div>
                </div>
                <div className="createTransaction-inline-fields-container"
                     key={`${name}--addRemove--${domainNum}`}
                     style={{marginBottom: "-6px",
                       display: "flex", justifyContent: "space-between"}}>
                  <span className="createTransaction-inline-field--half">
                    {numItemDomains > 1 && removeDomainButton(domainNum)}
                  </span>
                  <span className="createTransaction-inline-field--half"
                      style={{textAlign: "right"}}>
                    {addDomainButton(domainNum)}
                  </span>
                </div>
              </Fragment>
              )
            )}
            <div className="createTransaction-inline-fields-container">
              {ItemPriceField}
            </div>
          </Fragment>
        )
      }
      {role === 'broker' && BrokerCommissionField}
      <InputField
        disabled={disabled}
        name={`${name}.description`}
        label={`Item description${
          itemType === TRANSACTION_TYPES.MOTOR_VEHICLE ? ' (optional)' : ''
        }`}
        multiline
        validate={compose([
          (value) => validateItemDescription(value, itemType),
          maxChars(500, 'Item description'),
        ])}
      />
      <HiddenField
        label="Item type"
        name={`${name}.itemType`}
        initialValue={itemType}
        placeholder="Choose an item type"
        renderSelectedOption={(value) => TRANSACTION_TYPES_DISPLAY_NAME[value]}
      >
        {Object.entries(TRANSACTION_TYPES_DISPLAY_NAME).map(([key, value]) =>
          key === 'milestone' ? null : (
            <HiddenField
              value={key}
              key={key}
              data-tracking-section={V3_FORM_NAME}
              data-tracking-subsection="itemType"
              data-tracking-name="Item Type"
              data-tracking-value={key}
            >
              {value}
            </HiddenField>
          )
        )}
      </HiddenField>

      <AdditionalFields name={name} form={form} itemType={itemType} index={index} values={values} />
      <div className="createTransaction-button-container-right">
        {width >= DESKTOP_VIEW_WIDTH && ItemCancelButton}
        {width >= DESKTOP_VIEW_WIDTH && CSVBulkUpload}
        <Field name={`${name}.new`}>
          {(props) => (
            <Button
              onClick={() => {
                if (explicitlyNoDomainProvided) {
                  form.change(`${name}.domainNames`, null);
                } else {
                  Array(numItemDomains).map((
                    domainNum
                  ) => {
                    stripAllDomainPrefixes(domainNum);
                  });
                }
                onSubmit();
                values.new && firstItemIsMilestone && onAdd();
                props.input.onChange(false);
              }}
              variant="contained"
              disabled={!!error}
              color="primary"
              fullWidth={width < DESKTOP_VIEW_WIDTH}
              size="large"
              classes={{
                root: 'createTransaction-button-right',
              }}
            >
              {values.new ? 'Add item' : 'Update item'}
            </Button>
          )}
        </Field>
        {width < DESKTOP_VIEW_WIDTH && CSVBulkUpload}
        {width < DESKTOP_VIEW_WIDTH && ItemCancelButton}
      </div>
    </div>
  );
};

const AdditionalFields = ({ name, form, itemType, index, values }) => {
  const {
    isMilestone: firstItemMilestone,
    titleCollection: firstItemTitleCollection,
    lienHolder: firstItemLienHolder,
  } = form.getFieldState('items').value[0];
  const currency = form.getFieldState('currency').value;
  const { isMilestone } = values;

  const getCategorySpecificFields = () => {
    if (itemType === TRANSACTION_TYPES.GENERAL_MERCHANDISE) {
      return index === 0 ? (
        <SwitchField
          name={`${name}.isMilestone`}
          label="Set transaction items as milestones"
          initialValue={isMilestone}
          trackingName="Milestone"
        />
      ) : (
        <HiddenField
          name={`${name}.isMilestone`}
          fieldProps={{ type: 'checkbox', initialValue: firstItemMilestone }}
        />
      );
    }
    return null;
  };

  const getMainCategorySpecificFields = () => {
    switch (itemType) {
      case TRANSACTION_TYPES.MOTOR_VEHICLE:
        return (
          <MVAdditionalInfoInput
            name={name}
            values={values}
            index={index}
            upsells={{ firstItemTitleCollection, firstItemLienHolder }}
            currency={currency}
          />
        );
      case TRANSACTION_TYPES.GENERAL_MERCHANDISE:
        return (
          <GMAdditionalInfoInput
            name={name}
            values={values}
            isMilestone={firstItemMilestone}
            index={index}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Fragment>
      {getCategorySpecificFields()}
      {getMainCategorySpecificFields()}
    </Fragment>
  );
};
export default TransactionItemInputVariant;
